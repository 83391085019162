/* Specific styles for employee forms */
.employee-form-container {
    /* Custom styles for employee forms */
}

.employee-form-group {
    /* Custom styles for employee form groups */
}
/* Form Container */
.form-container {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
    max-width: 900px;
    margin: 20px auto;
}

/* Form Title */
.form-container h2 {
    margin-bottom: 20px;
}

/* Form */
.employee-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

/* Form Rows */
.employee-form .form-row {
    display: flex;
    flex-direction: column;
}

/* Full-width fields */
.employee-form .form-row:nth-child(2n) {
    grid-column: span 2;
}

/* Form Labels */
.employee-form label {
    margin-bottom: 5px;
    font-weight: bold;
}

/* Form Inputs */
.employee-form input[type="text"],
.employee-form input[type="number"],
.employee-form input[type="date"],
.employee-form input[type="email"],
.employee-form input[type="radio"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

/* Form Actions */
.employee-form .form-actions {
    grid-column: span 2;
    text-align: right;
}

.employee-form .form-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.employee-form .form-actions button:hover {
    background-color: #0056b3;
}
