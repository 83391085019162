.register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    margin: 0 auto;
}

.register-container h2 {
    font-family: Arial, sans-serif;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.register-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.register-form input,
.register-form select {
    font-size: 16px;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #f7f7f7;
    outline: none;
    width: 100%;
}

.register-form input:focus,
.register-form select:focus {
    border-color: #007bff;
    background-color: #e6f0ff;
}

.register-form button {
    font-size: 18px;
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.register-form button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    margin-top: 10px;
}
