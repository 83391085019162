/* You can adjust these styles as needed */
.container {
  padding: 20px;
  margin-left: 200px; /* This ensures the calendar doesn't overlap the sidebar */
}

.full-calendar {
  margin-top: 20px;
}

    
  